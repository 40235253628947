import React, { useEffect, useState } from "react";
// import Swiper core and required modules
import type SwiperClass from "swiper/types/swiper-class";
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./banner.module.css";
import Image from "@/components/image";
import IconFont from "@/components/iconfont";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Link from "../link";
import classnames from "classnames";
import useBrowse from "@/hooks/useBrowse";

type Props = {
  bannerData: Banner[];
};

export default function Banner(props: Props) {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [showNavigation, setShowNavigation] = useState<boolean>(false);

  useEffect(() => {
    if (swiper && swiper.autoplay) {
      const swiperEl = document.getElementById("swiper");
      if (swiperEl) {
        swiperEl.onmouseout = () => {
          setShowNavigation(false);
        };
        swiperEl.onmouseover = () => {
          setShowNavigation(true);
        };
      }
    }
  }, [swiper, swiper?.autoplay]);

  const bannerHref = (item: Banner): string => {
    switch (item.bannerJumpType) {
      case 1:
        return `/detail/news/${item.bannerJumpId}`;
      case 4:
        return item.bannerOffStationJumpUrl;
      case 9:
        return `/`;
      default:
        return "javascript:;";
    }
  };

  // 广告点击 手动触发
  const { run } = useBrowse({ url: '/api/tracking', manual: true });

  return (
    <header aria-label="广告">
      <Swiper
        id="swiper"
        className={styles.Swiper}
        modules={[Autoplay, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        speed={300}
        loop={true}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        initialSlide={0} // 初始化显示哪一个
        onSwiper={(swiper: SwiperClass) => {
          setSwiper(swiper);
        }}
      >
        {showNavigation && (
          <IconFont
            type="icon-houtui"
            className={classnames(styles.SwiperPrev, styles.SwiperButton)}
            onClick={() => {
              swiper?.slidePrev();
            }}
          />
        )}
        {props.bannerData &&
          props.bannerData.map((item, index) => {
            return (
              <SwiperSlide
                key={item.id}
                virtualIndex={index}
                className={styles.SwiperSlide}
                style={{
                  background: item?.bannerColorValue ?? "var(--color-white)",
                }}
              >
                <Link
                  href={bannerHref(item)}
                  target={item.bannerJumpType === 4 ? '_blank' : '_self'}
                  onClick={() => {
                    run({ dataId: item.id, dataType: 7, thirdChannelId: 4 });
                  }}
                >
                  <Image
                    src={item.bannerPictureUrl}
                    alt={item.bannerTitle}
                    className={styles.bannerImg}
                    height={400}
                    width={1280}
                  />
                </Link>
              </SwiperSlide>
            );
          })}

        {showNavigation && (
          <IconFont
            type="icon-qianjin"
            className={classnames(styles.SwiperNext, styles.SwiperButton)}
            onClick={() => {
              swiper?.slideNext();
            }}
          />
        )}
      </Swiper>

    </header>
  );
}
