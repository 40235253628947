import Image from "@/components/image";
import { useMemo } from "react";
import styles from "./news.module.css";
import Tooltip from "../tooltip";
import classnames from "classnames";
import Link from '@/components/link';

type NewsProps = {
  type: 'news' | 'activity';

  title: string;
  content: string;
  image: string;
  imageAlt: string;
  userActivityStatus?: number;
  miniprogramCode?: string;
  id?: number;

  gmtStart?: string;
  gmtEnd?: string;
  activityType?: 1 | 2 | 3 | 4 | 5 | 6;

  friendlyTime?: string;
};

export default function News(props: NewsProps) {
  const { title, content, image, imageAlt, userActivityStatus } = props;

  const btnText = useMemo(() => {
    switch (userActivityStatus) {
      case 1:
        return "预约";
      case 2:
        return "即将开始";
      case 3:
        return "直播中";
      case 4:
        return "往期活动";
      case 5:
        return "评审中";
      case 6:
        return "投票中";
      case 7:
        return "无状态";
      case 8:
        return "报名中";
      case 9:
        return "敬请期待";
      case 10:
        return "活动中";
      case 11:
        return "回放";
      default:
        return "其他";
    }
  }, [userActivityStatus]);

  const getTime = useMemo(() => {
    if (props.activityType) {
      switch (props.activityType) {
        case 1:
        case 3:
        case 4:
        case 5:
        case 6:
          return `${props.gmtStart?.split(" ")[0]} 至 ${props.gmtEnd?.split(" ")[0]
            }`;
        case 2:
          if (!props.gmtStart) return "未知时间开播";
          const time = props.gmtStart.split(" ");
          return `${time[0]} ${time[1].slice(0, time[1].lastIndexOf(":"))}开播`;
      }
    } else {
      if (!props?.friendlyTime) return "未知时间";

      const time = props.friendlyTime.split(" ");
      return time[0]
    }
  }, [props.activityType, props.gmtStart, props.gmtEnd, props.friendlyTime]);

  return (
    <Link
      className={styles.news_item__wrapper}
      href={`/detail/${props.type}/${props.id}`}
    >
      <div className={classnames(styles.news_tab_panel_item, 'pointer')}>
        <div className={styles.tab_panel_item_context}>
          <h2 className={styles.title} title={title}>{title}</h2>
          <p className={styles.content} title={content}>{content}</p>
          <time className={styles.time}>{getTime}</time>

          {userActivityStatus !== undefined && (
            <Tooltip
              placement="right"
              miniProgramCode={props.miniprogramCode || ""}
              miniProgramTitle={"手机扫码参与活动"}
              miniProgramContent={"扫码参加活动报名"}
            >
              <button className={classnames('status_btn', styles.btn)} data-btn-status={userActivityStatus}>
                {btnText}
              </button>
            </Tooltip>
          )}

          {
            userActivityStatus === undefined && (
              <button className={classnames(styles.detail_btn, styles.btn)}>
                查看详情 {'>'}
              </button>
            )
          }
        </div>
        <div className={styles.tab_panel_item_image}>
          <Image className={styles.image} src={image} alt={imageAlt} width={158} height={158} />
        </div>
      </div>
    </Link>
  );
}
