import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./member.module.css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import Image from "@/components/image";
import IconFont from "@/components/iconfont";

import "swiper/css";
import { MemberType } from "@/constants";
import Link from "../link";
import classnames from "classnames";

type MemberProps = {
  dataSource: PartnerMap;
};

export default function Member(props: MemberProps) {
  const { dataSource } = props;

  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [activeKey, setActiveKey] = useState<MemberKey>("1");
  const [showNavigation, setShowNavigation] = useState<boolean>(false);

  useEffect(() => {
    if (swiper && swiper.autoplay) {
      const swiperEl = document.getElementById("swiper1");
      if (swiperEl) {
        swiperEl.onmouseout = () => {
          setShowNavigation(false);
        };
        swiperEl.onmouseover = () => {
          setShowNavigation(true);
        };
      }
    }
  }, [swiper, swiper?.autoplay]);

  const tabs = useMemo(() => {
    return Object.keys(dataSource) as MemberKey[];
  }, [dataSource]);

  const renderTab = (key: MemberKey) => {
    if (!dataSource[key] || !dataSource[key].length) return null;
    const type = MemberType[`member_${key}`];
    return (
      <button
        key={key}
        className={classNames("tab_item", {
          ["tab_item_active"]: activeKey === key,
        })}
        onClick={() => setActiveKey(key)}
        aria-current={activeKey === key ? "true" : "false"}
      >
        {type}
      </button>
    );
  };

  const renderMember = (key: MemberKey) => {
    const data = dataSource[key];
    return (
      data &&
      data.map((item) => (
        <SwiperSlide key={item.id} className={styles.member_swiper_item}>
          <Link className={styles.link} title={item.organizationName} aria-label="跳转成员机构详情页" href={`/detail/member_${key}/${item.articleId}`}>
            <Image
              className={styles.member_swiper_item_img}
              src={item.partnersLogo}
              alt={item.organizationName}
              width={161}
              height={100}
            />
            <h4>{item.organizationName}</h4>
          </Link>
        </SwiperSlide>
      ))
    );
  };

  return (
    <>
      <div className={styles.member_wrapper_title}>
        {tabs.map((key) => renderTab(key))}
      </div>
      <div className={styles.member_wrapper_content}>
        <Swiper
          id="swiper1"
          className={styles.member_swiper}
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={"auto"}
          centeredSlides={false}
          autoplay={{
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          onSwiper={(swiper: SwiperClass) => {
            setSwiper(swiper);
          }}
        >
          {showNavigation && <IconFont
            type="icon-houtui"
            className={classnames(styles.SwiperPrev, styles.SwiperButton)}
            onClick={(e) => {
              swiper?.slidePrev();
              e.stopPropagation();
            }}
          />}
          {renderMember(activeKey)}
          {showNavigation && <IconFont
            type="icon-qianjin"
            className={classnames(styles.SwiperNext, styles.SwiperButton)}
            onClick={(e) => {
              swiper?.slideNext();
              e.stopPropagation();
            }}
          />}
        </Swiper>
      </div>
    </>
  );
}
